@font-face {
	font-family: EBGaramond;
	src: url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap')
}

@font-face {
	font-family: GARA;
	src: url('./fonts/GARA.TTF')
		/* fave */
}

@font-face {
	font-family: GARABD;
	src: url('./fonts/GARABD.TTF')
}

@font-face {
	font-family: GARAIT;
	src: url('./fonts/GARAIT.TTF')
}

@font-face {
	font-family: GARAM;
	src: url('./fonts/GARAM.ttf')
}

@font-face {
	font-family: Garamond;
	src: url('./fonts/Garamond.ttf')
}

@font-face {
	font-family: GaramondBold;
	src: url('./fonts/Garamond\ Bold\ font.ttf')
}

@font-face {
	font-family: GaramondMediumItalic;
	src: url('./fonts/Garamond\ Medium\ Italic.ttf')
}

@font-face {
	font-family: GaramondRegular;
	src: url('./fonts/Garamond\ Regular.ttf')
}

@font-face {
	font-family: GaramondRoman;
	src: url('./fonts/Garamond\ Roman.ttf')
}

@font-face {
	font-family: Garamndmi;
	src: url('./fonts/garmndmi.ttf')
}

@font-face {
	font-family: GARR66W;
	src: url('./fonts/GARR66W.TTF')
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

body {
	line-height: 1 !important;
	font-family: GARA !important;
	color: #e3eff3 !important;
	background-color: #143a52 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: GARABD !important;
}

ol,
ul {
	list-style: none;
}

blockquote,
q {
	quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

.nav-item {
	list-style-type: none;
	margin: 0;
	padding: 0;
}